.lds-ripple {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0px 0px -50px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #000;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.confirm-box {
    background-color: #181D25;
    padding: 20px;
    line-height: 20px;
    color: #FFF;
    border-radius: 2px;
    width: 20em;
}

.confirm-box .title,
.confirm-box .sub-title {
    font-size: xx-small;
}

.confirm-box .content {
    font-weight: 700;
    font-size: x-large;
    margin: .5em 0;
}

.react-confirm-alert-button-group>button {
    text-transform: uppercase;
    font-weight: bold;
}