@import url(https://fonts.googleapis.com/css?family=Material+Icons|Work+Sans|Libre+Franklin|Space+Mono:400,700,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    overflow: hidden;
    background-color: #2a313e;
}

.roulette {
    font-family: 'Work Sans', sans-serif;
    display: block;
    position: absolute;
    width: 400px;
    height: 400px;
    top: 50%;
    left: 50%;
    margin-top: -200px;
    margin-left: -200px;
    zoom: 1.5;
}

.roulette .shadow {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    box-shadow: 0 0.1em 0 rgba(0, 0, 0, 0.25) inset;
}

.roulette .markers {
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    overflow: hidden;
    border-radius: 100%;
}

.roulette .markers .marker {
    position: absolute;
    width: 0;
    height: 0;
    top: -200px;
    left: 200px;
    transform-origin: 0% 400px;
    border: 0 solid transparent;
}

.roulette .markers .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1em 0 1em 1em;
    border-color: transparent transparent transparent #007bff;
    position: absolute;
    border-left-color: #2a313e;
    top: 50%;
    left: -1px;
    margin-top: -1em;
    /* -webkit-filter: drop-shadow(0 0.25em 0 rgba(0, 0, 0, 0.25)); */
    /* filter: drop-shadow(0 0.25em 0 rgba(0, 0, 0, 0.25)); */
}

.roulette .spinner {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    overflow: hidden;
    transform: rotateZ(0deg);
    /*performance boost*/
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.roulette .spinner .item {
    position: absolute;
    width: 0;
    height: 0;
    top: -200px;
    left: 200px;
    transform-origin: 0% 400px;
    border: 0 solid transparent;
}

.roulette .spinner .item .label {
    display: block;
    position: absolute;
    color: #FFF;
    font-family: "Libre Franklin", sans-serif;
    font-weight: 700;
    top: 0;
    left: 0;
    white-space: nowrap;
    transform-origin: 0 0;
    font-size: .5em;
}

.roulette .spinner .item .label i,
.roulette .spinner .item .label .text {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-size: 0.63em;
    text-indent: 0;
}

.roulette .spinner .item .label i {
    margin-right: 0.1em;
}

.roulette .button {
    width: 8em;
    height: 8em;
    line-height: 8em;
    top: 50%;
    left: 50%;
    margin-left: -4em;
    margin-top: -4em;
    font-family: "Space Mono", monospace;
    font-weight: 800;
    text-transform: uppercase;
    z-index: 10;
    position: absolute;
    background: #EB3D35;
    border: none;
    border-radius: 100%;
    color: #FFF;
    outline: none;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-shadow: 0 0.1em 0 rgba(0, 0, 0, 0.25);
    text-align: center;
    transition: transform 0.15s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.roulette .button:hover {
    color: #F8F8F8;
}

.roulette .button span {
    font-size: 2.6em;
    letter-spacing: -0.05em;
}

.roulette.busy .button {
    transform: scale(0.9);
    box-shadow: 0 0.15em 0 rgba(0, 0, 0, 0.25);
    color: #FFF;
    cursor: default;
}


/* CUSTOM LABELS */

.roulette .spinner .item[data-type="quiz"] .label {
    font-size: 1.5em;
}

.roulette .spinner .item[data-type="question"] .label {
    font-size: 1.3em;
    font-weight: 600;
}

.roulette .spinner .item[data-type="replay"] .label .text {
    font-size: .6em;
    white-space: normal;
    white-space: initial;
    width: 1em;
    text-align: center;
    line-height: 1.2;
}

.roulette .spinner .item[data-type="replay"] .label i {
    font-size: 1.5em;
}

.roulette .spinner .item[data-type="time"] .label i {
    font-size: 1.5em;
}
.lds-ripple {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0px 0px -50px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #000;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.confirm-box {
    background-color: #181D25;
    padding: 20px;
    line-height: 20px;
    color: #FFF;
    border-radius: 2px;
    width: 20em;
}

.confirm-box .title,
.confirm-box .sub-title {
    font-size: xx-small;
}

.confirm-box .content {
    font-weight: 700;
    font-size: x-large;
    margin: .5em 0;
}

.react-confirm-alert-button-group>button {
    text-transform: uppercase;
    font-weight: bold;
}
